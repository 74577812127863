@import 'variables';
@import 'theme';

@import 'normalize';
@import 'scaffolding';
@import 'typography';

@import 'grid';
.row {
  @include clearfix;
  display: block;
}

@import 'icons-hb';

@import 'honeybook';
.nx-button {
  /* These attributes affect font rendering, it will eventually be added to hb_scss */
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  border: 0;
}

html {
    font-size: 100%;
}

#Artboard {
  fill: #881155 !important;
}