.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5384616;
    color: inherit;
}
.h4, h4 {
    font-size: 19px;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    letter-spacing: -.015em;
}

.headline {
    margin-top: 0;
}
strong {
    font-weight: 600;
}

pre,
code {
    font-family: Open Sans, monospace;
}