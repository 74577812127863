@font-face {
  font-family: 'iconbasic';
  src: url('https://cdn.wedding-spot.com/fonts/iconbasic.woff2') format('woff2');
  font-display: swap;
}
[class^="icon-hb-"], [class*=" icon-hb-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconbasic' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-hb-instagram:before {
  content: "\e9ad";
}
.icon-hb-nx-file-plain:before {
  content: "\e9ff";
}
.icon-hb-nx-image:before {
  content: "\e9fd";
}
.icon-hb-nx-bell:before {
  content: "\e970";
}
.icon-hb-nx-dollar-circle:before {
  content: "\e9fa";
}
.icon-hb-nx-gift:before {
  content: "\e9fb";
}
.icon-hb-nx-clock:before {
  content: "\e9f6";
}
.icon-hb-nx-bookmark:before {
  content: "\e9f5";
}
.icon-hb-nx-calendar-star:before {
  content: "\e9f0";
}
.icon-hb-nx-cart:before {
  content: "\e9f1";
}
.icon-hb-nx-opposite-arrows:before {
  content: "\e9f2";
}
.icon-hb-nx-square-arrow:before {
  content: "\e9eb";
}
.icon-hb-nx-badge:before {
  content: "\e9cd";
}
.icon-hb-nx-medal:before {
  content: "\e9ce";
}
.icon-hb-nx-profile:before {
  content: "\e9c0";
}
.icon-hb-calendly-editor:before {
  content: "\e9c7";
}
.icon-hb-nx-pencil:before {
  content: "\e9bd";
}
.icon-hb-nx-dollar-refresh:before {
  content: "\e9bc";
}
.icon-hb-nx-file-agreement:before {
  content: "\e9b4";
}
.icon-hb-nx-file-brochure:before {
  content: "\e9b5";
}
.icon-hb-nx-file-invoice:before {
  content: "\e9b6";
}
.icon-hb-nx-file-proposal:before {
  content: "\e9b7";
}
.icon-hb-nx-file-questionnaire:before {
  content: "\e9b8";
}
.icon-hb-nx-file-timeline:before {
  content: "\e9b9";
}
.icon-hb-nx-heart-filled:before {
  content: "\e9af";
}
.icon-hb-nx-heart:before {
  content: "\e9b0";
}
.icon-hb-nx-share:before {
  content: "\e9b1";
}
.icon-hb-nx-facebook-square:before {
  content: "\e9a9";
}
.icon-hb-nx-instagram:before {
  content: "\e9aa";
}
.icon-hb-nx-pinterest:before {
  content: "\e9ab";
}
.icon-hb-nx-web:before {
  content: "\e9ac";
}
.icon-hb-nx-tasks:before {
  content: "\e9a1";
}
.icon-hb-nx-close:before {
  content: "\e9a8";
}
.icon-hb-nx-file:before {
  content: "\e9a7";
}
.icon-hb-nx-archive:before {
  content: "\e9a3";
}
.icon-hb-nx-cog:before {
  content: "\e9a4";
}
.icon-hb-nx-logout:before {
  content: "\e9a5";
}
.icon-hb-nx-question-mark:before {
  content: "\e9a6";
}
.icon-hb-nx-automation:before {
  content: "\e999";
}
.icon-hb-nx-clip:before {
  content: "\e99a";
}
.icon-hb-nx-connected:before {
  content: "\e9fc";
}
.icon-hb-nx-dots:before {
  content: "\e99c";
}
.icon-hb-nx-lead:before {
  content: "\e99d";
}
.icon-hb-nx-notes:before {
  content: "\e99e";
}
.icon-hb-nx-plus:before {
  content: "\e99f";
}
.icon-hb-nx-stage:before {
  content: "\e9a0";
}
.icon-hb-nx-arrow-down:before {
  content: "\e995";
}
.icon-hb-nx-back:before {
  content: "\e987";
}
.icon-hb-nx-buildings:before {
  content: "\e988";
}
.icon-hb-nx-check:before {
  content: "\e989";
}
.icon-hb-nx-email:before {
  content: "\e98a";
}
.icon-hb-nx-lock:before {
  content: "\e98b";
}
.icon-hb-nx-phone:before {
  content: "\e98c";
}
.icon-hb-nx-search:before {
  content: "\e98d";
}
.icon-hb-nx-star-filled:before {
  content: "\e98e";
}
.icon-hb-nx-star:before {
  content: "\e98f";
}
.icon-hb-nx-trash:before {
  content: "\e990";
}
.icon-hb-nx-user:before {
  content: "\e991";
}
.icon-hb-nx-megaphone:before {
  content: "\e985";
}
.icon-hb-nx-other:before {
  content: "\e97f";
}
.icon-hb-nx-users:before {
  content: "\e980";
}
.icon-hb-nx-briefcase:before {
  content: "\e96e";
}
.icon-hb-nx-calendar:before {
  content: "\e96f";
}
.icon-hb-nx-check-circle:before {
  content: "\e99b";
}
.icon-hb-nx-dollar:before {
  content: "\e971";
}
.icon-hb-nx-email-exclamation:before {
  content: "\e972";
}
.icon-hb-nx-location-pin:before {
  content: "\e973";
}
.icon-hb-nx-message:before {
  content: "\e974";
}
.icon-hb-nx-messages:before {
  content: "\e975";
}
.icon-hb-nx-new-automated:before {
  content: "\e976";
}
.icon-hb-nx-signature:before {
  content: "\e977";
}
.icon-hb-nx-new-trialer:before {
  content: "\e978";
}
.icon-hb-nx-plus-user:before {
  content: "\e979";
}
.icon-hb-nx-settings:before {
  content: "\e97a";
}
.icon-hb-nx-smile:before {
  content: "\e97b";
}
.icon-hb-nx-pvl:before {
  content: "\e97c";
}
.icon-hb-connect:before {
  content: "\e95f";
}
.icon-hb-preferences:before {
  content: "\e95d";
}
.icon-hb-user:before {
  content: "\e95e";
}
.icon-hb-expand:before {
  content: "\e95c";
}
.icon-hb-information:before {
  content: "\e955";
}
.icon-hb-account:before {
  content: "\e94e";
}
.icon-hb-facebook:before {
  content: "\e94f";
}
.icon-hb-hearth-b:before {
  content: "\e950";
}
.icon-hb-logout:before {
  content: "\e951";
}
.icon-hb-settings-c:before {
  content: "\e952";
}
.icon-hb-world:before {
  content: "\e953";
}
.icon-hb-contactform-b:before {
  content: "\e922";
}
.icon-hb-lock-b2:before {
  content: "\e6be";
}
.icon-hb-tag-b:before {
  content: "\e6b1";
}
.icon-hb-snowflake-b:before {
  content: "\e6ab";
}
.icon-hb-paymentschedule-b:before {
  content: "\e6a4";
}
.icon-hb-budget:before {
  content: "\e611";
}
.icon-hb-edit:before {
  content: "\e620";
}
.icon-hb-trash2:before {
  content: "\e64c";
}
.icon-hb-calendar-b2:before {
  content: "\e66e";
}
.icon-hb-happyface-b:before {
  content: "\e677";
}
.icon-hb-nx-file-plain-16:before {
  content: "\ea00";
}
.icon-hb-tool-timetrack:before {
  content: "\e9fe";
}
.icon-hb-tool-contact-form:before {
  content: "\e9f9";
}
.icon-hb-nx-bookmark-16:before {
  content: "\e9f3";
}
.icon-hb-nx-calendar-16:before {
  content: "\e9f4";
}
.icon-hb-nx-minus-16:before {
  content: "\e9ec";
}
.icon-hb-nx-clock-16:before {
  content: "\e9de";
}
.icon-hb-nx-file-type-triangle-16:before {
  content: "\e9db";
}
.icon-hb-nx-home-16:before {
  content: "\e9cc";
}
.icon-hb-nx-cog-16:before {
  content: "\e9cb";
}
.icon-hb-nx-filter-16:before {
  content: "\e9ca";
}
.icon-hb-nx-close-16:before {
  content: "\e9c9";
}
.icon-hb-nx-check-16:before {
  content: "\e9c8";
}
.icon-hb-calendly-editor-16:before {
  content: "\e9c6";
}
.icon-hb-nx-download-16:before {
  content: "\e9c3";
}
.icon-hb-nx-star-16:before {
  content: "\e9c4";
}
.icon-hb-nx-trash-16:before {
  content: "\e9c5";
}
.icon-hb-nx-upload-cloud:before {
  content: "\e9c2";
}
.icon-hb-nx-traingle:before {
  content: "\e9c1";
}
.icon-hb-nx-participants:before {
  content: "\e9bf";
}
.icon-hb-nx-pencil-16:before {
  content: "\e9be";
}
.icon-hb-nx-question-mark-circle-16:before {
  content: "\e9bb";
}
.icon-hb-nx-check-mark-circle-16:before {
  content: "\e9b2";
}
.icon-hb-nx-exclamation-mark-circle-16:before {
  content: "\e9b3";
}
.icon-hb-nx-mobile-check-16:before {
  content: "\e9ba";
}
.icon-hb-nx-connected-16:before {
  content: "\e9ae";
}
.icon-hb-nx-hamburger-16:before {
  content: "\e9a2";
}
.icon-hb-nx-arrow-up-16:before {
  content: "\e996";
}
.icon-hb-nx-lock-16:before {
  content: "\e997";
}
.icon-hb-nx-plus-16:before {
  content: "\e998";
}
.icon-hb-nx-arrow-down-16:before {
  content: "\e994";
}
.icon-hb-nx-globe-16:before {
  content: "\e992";
}
.icon-hb-nx-location-pin-16:before {
  content: "\e993";
}
.icon-hb-nx-eye:before {
  content: "\e986";
}
.icon-hb-tool-email:before {
  content: "\e97e";
}
.icon-hb-nx-new-inquiry:before {
  content: "\e97d";
}
.icon-hb-tool-benefits:before {
  content: "\e960";
}
.icon-hb-tool-bookkeeping:before {
  content: "\e961";
}
.icon-hb-tool-calendar:before {
  content: "\e962";
}
.icon-hb-tool-collectives:before {
  content: "\e963";
}
.icon-hb-tool-contact:before {
  content: "\e964";
}
.icon-hb-tool-creative:before {
  content: "\e965";
}
.icon-hb-tool-iOS:before {
  content: "\e966";
}
.icon-hb-tool-library:before {
  content: "\e967";
}
.icon-hb-tool-opportunities:before {
  content: "\e968";
}
.icon-hb-tool-quickbooks:before {
  content: "\e969";
}
.icon-hb-tool-reports:before {
  content: "\e96a";
}
.icon-hb-tool-tasks:before {
  content: "\e96b";
}
.icon-hb-tool-templates:before {
  content: "\e96c";
}
.icon-hb-tool-workflows:before {
  content: "\e96d";
}
.icon-hb-chat:before {
  content: "\e95a";
}
.icon-hb-share:before {
  content: "\e95b";
}
.icon-hb-questionnaire-outline:before {
  content: "\e958";
}
.icon-hb-brochure-outline:before {
  content: "\e959";
}
.icon-hb-lock3:before {
  content: "\e954";
}
.icon-hb-heart-hands:before {
  content: "\e93e";
}
.icon-hb-money:before {
  content: "\e93f";
}
.icon-hb-safe:before {
  content: "\e940";
}
.icon-hb-question-mark:before {
  content: "\e93c";
}
.icon-hb-refund:before {
  content: "\e93b";
}
.icon-hb-hair-makeup:before {
  content: "\e928";
}
.icon-hb-support:before {
  content: "\e929";
}
.icon-hb-glasses:before {
  content: "\e92a";
}
.icon-hb-bookmark:before {
  content: "\e926";
}
.icon-hb-search:before {
  content: "\e927";
}
.icon-hb-signature:before {
  content: "\e900";
}
.icon-hb-time2:before {
  content: "\e913";
}
.icon-hb-arrows-up-down:before {
  content: "\e947";
}
.icon-hb-calendar4:before {
  content: "\e946";
}
.icon-hb-watch:before {
  content: "\e923";
}
.icon-hb-arrow-curved-left:before {
  content: "\e920";
}
.icon-hb-adduser2:before {
  content: "\e935";
}
.icon-hb-notes2:before {
  content: "\e936";
}
.icon-hb-email2:before {
  content: "\e937";
}
.icon-hb-profile2:before {
  content: "\e938";
}
.icon-hb-trophy2:before {
  content: "\e91e";
}
.icon-hb-nametag-b:before {
  content: "\e91c";
}
.icon-hb-time:before {
  content: "\e924";
}
.icon-hb-calendar2:before {
  content: "\e91b";
}
.icon-hb-preferredvendorlist:before {
  content: "\e918";
}
.icon-hb-guide-b:before {
  content: "\e919";
}
.icon-hb-phone2:before {
  content: "\e917";
}
.icon-hb-newwindow:before {
  content: "\e91a";
}
.icon-hb-budget2:before {
  content: "\e910";
}
.icon-hb-location2:before {
  content: "\e911";
}
.icon-hb-group:before {
  content: "\e912";
}
.icon-hb-balloon2:before {
  content: "\e914";
}
.icon-hb-code:before {
  content: "\e915";
}
.icon-hb-help:before {
  content: "\e916";
}
.icon-hb-info:before {
  content: "\e90e";
}
.icon-hb-question:before {
  content: "\e674";
}
.icon-hb-teardrop:before {
  content: "\e90d";
}
.icon-hb-thumbsdownfill:before {
  content: "\e90b";
}
.icon-hb-thumbsup:before {
  content: "\e909";
}
.icon-hb-thumbsupfill:before {
  content: "\e90c";
}
.icon-hb-thumbsdown:before {
  content: "\e90a";
}
.icon-hb-phone:before {
  content: "\e907";
}
.icon-hb-lock:before {
  content: "\e908";
}
.icon-hb-export-b:before {
  content: "\e906";
}
.icon-hb-officiant:before {
  content: "\e905";
}
.icon-hb-client:before {
  content: "\e901";
}
.icon-hb-design:before {
  content: "\e902";
}
.icon-hb-team:before {
  content: "\e903";
}
.icon-hb-vendors:before {
  content: "\e925";
}
.icon-hb-events-b:before {
  content: "\e904";
}
.icon-hb-preferredvendor:before {
  content: "\e800";
}
.icon-hb-timeline:before {
  content: "\e801";
}
.icon-hb-brochure:before {
  content: "\e6ce";
}
.icon-hb-assign:before {
  content: "\e6cb";
}
.icon-hb-refer:before {
  content: "\e6cd";
}
.icon-hb-drag:before {
  content: "\e600";
}
.icon-hb-lighting:before {
  content: "\e6c9";
}
.icon-hb-cinematography:before {
  content: "\e6ca";
}
.icon-hb-paid4-b:before {
  content: "\e6c7";
}
.icon-hb-time-b:before {
  content: "\e6c8";
}
.icon-hb-download2-b:before {
  content: "\e6c6";
}
.icon-hb-checkbox-b:before {
  content: "\e6c4";
}
.icon-hb-checkbox-empty-b:before {
  content: "\e6c5";
}
.icon-hb-arrowdown:before {
  content: "\e601";
}
.icon-hb-arrowup:before {
  content: "\e602";
}
.icon-hb-attachment-b:before {
  content: "\e6c3";
}
.icon-hb-newmessage-b:before {
  content: "\e6bf";
}
.icon-hb-questionnaire:before {
  content: "\e6f2";
}
.icon-hb-proposal:before {
  content: "\e6f3";
}
.icon-hb-invoice:before {
  content: "\e6f4";
}
.icon-hb-agreement:before {
  content: "\e6f5";
}
.icon-hb-request:before {
  content: "\e6f6";
}
.icon-hb-gallery:before {
  content: "\e6f1";
}
.icon-hb-lock-b:before {
  content: "\e6bd";
}
.icon-hb-pipeline-b:before {
  content: "\e610";
}
.icon-hb-eventinfo:before {
  content: "\e6b2";
}
.icon-hb-packageselect:before {
  content: "\e6b3";
}
.icon-hb-personalinfo:before {
  content: "\e6b4";
}
.icon-hb-statictextarea:before {
  content: "\e6b5";
}
.icon-hb-textinput:before {
  content: "\e6b6";
}
.icon-hb-checkboxes:before {
  content: "\e6b7";
}
.icon-hb-dropdownmenu:before {
  content: "\e6b8";
}
.icon-hb-multiplechoice:before {
  content: "\e6b9";
}
.icon-hb-cancel-b2:before {
  content: "\e6af";
}
.icon-hb-minimize-b:before {
  content: "\e6ad";
}
.icon-hb-expand-b:before {
  content: "\e6ae";
}
.icon-hb-header-b:before {
  content: "\e6ac";
}
.icon-hb-clearformatting-b:before {
  content: "\e6aa";
}
.icon-hb-inputfield-b:before {
  content: "\e6a8";
}
.icon-hb-template-b:before {
  content: "\e6a9";
}
.icon-hb-aligncenter-b:before {
  content: "\e61f";
}
.icon-hb-alignleft-b:before {
  content: "\e621";
}
.icon-hb-alignright-b:before {
  content: "\e626";
}
.icon-hb-bullet-b:before {
  content: "\e68a";
}
.icon-hb-clipboard-b:before {
  content: "\e68b";
}
.icon-hb-highlight-b:before {
  content: "\e691";
}
.icon-hb-insertline:before {
  content: "\e6a0";
}
.icon-hb-italic-b:before {
  content: "\e6a1";
}
.icon-hb-justified-b:before {
  content: "\e6a2";
}
.icon-hb-numberlist-b:before {
  content: "\e6a3";
}
.icon-hb-photo2-b:before {
  content: "\e6a5";
}
.icon-hb-textcolor-b:before {
  content: "\e6a7";
}
.icon-hb-underline-b:before {
  content: "\e6c0";
}
.icon-hb-add1:before {
  content: "\e604";
}
.icon-hb-add2:before {
  content: "\e605";
}
.icon-hb-addfile:before {
  content: "\e69b";
}
.icon-hb-adduser22:before {
  content: "\e606";
}
.icon-hb-aligncenter:before {
  content: "\e607";
}
.icon-hb-alignleft:before {
  content: "\e608";
}
.icon-hb-alignright:before {
  content: "\e609";
}
.icon-hb-archive:before {
  content: "\e661";
}
.icon-hb-arrows:before {
  content: "\e60a";
}
.icon-hb-back1:before {
  content: "\e60b";
}
.icon-hb-back2:before {
  content: "\e60c";
}
.icon-hb-back3:before {
  content: "\e60e";
}
.icon-hb-baker:before {
  content: "\e60f";
}
.icon-hb-bold-b:before {
  content: "\e6c1";
}
.icon-hb-booking:before {
  content: "\e6c2";
}
.icon-hb-bullet:before {
  content: "\e612";
}
.icon-hb-calendar:before {
  content: "\e65c";
}
.icon-hb-call:before {
  content: "\e613";
}
.icon-hb-caterer:before {
  content: "\e614";
}
.icon-hb-check1:before {
  content: "\e615";
}
.icon-hb-check2:before {
  content: "\e616";
}
.icon-hb-checkmark-fill:before {
  content: "\e91f";
}
.icon-hb-checkmark3:before {
  content: "\e617";
}
.icon-hb-comment:before {
  content: "\e618";
}
.icon-hb-company:before {
  content: "\e66f";
}
.icon-hb-compass:before {
  content: "\e69c";
}
.icon-hb-creditcard:before {
  content: "\e689";
}
.icon-hb-idcard:before {
  content: "\e6a6";
}
.icon-hb-dashboard:before {
  content: "\e619";
}
.icon-hb-deleteuser2:before {
  content: "\e61b";
}
.icon-hb-decor:before {
  content: "\e61a";
}
.icon-hb-dj:before {
  content: "\e61c";
}
.icon-hb-download:before {
  content: "\e61d";
}
.icon-hb-dropdown:before {
  content: "\e61e";
}
.icon-hb-duplicate:before {
  content: "\e69a";
}
.icon-hb-email-62:before {
  content: "\e60d";
}
.icon-hb-exit4:before {
  content: "\e622";
}
.icon-hb-exit2:before {
  content: "\e623";
}
.icon-hb-fashion:before {
  content: "\e624";
}
.icon-hb-fashion2:before {
  content: "\e625";
}
.icon-hb-file:before {
  content: "\e69d";
}
.icon-hb-florist:before {
  content: "\e627";
}
.icon-hb-gridview_active:before {
  content: "\e628";
}
.icon-hb-gridview_nonactive:before {
  content: "\e629";
}
.icon-hb-guest:before {
  content: "\e62a";
}
.icon-hb-happyface:before {
  content: "\e62b";
}
.icon-hb-honeynews:before {
  content: "\e62c";
}
.icon-hb-italic:before {
  content: "\e62d";
}
.icon-hb-justified:before {
  content: "\e62e";
}
.icon-hb-key:before {
  content: "\e62f";
}
.icon-hb-link_16:before {
  content: "\e630";
}
.icon-hb-link:before {
  content: "\e631";
}
.icon-hb-listview_active:before {
  content: "\e632";
}
.icon-hb-listview_inactive:before {
  content: "\e633";
}
.icon-hb-location:before {
  content: "\e634";
}
.icon-hb-makeup:before {
  content: "\e635";
}
.icon-hb-more1:before {
  content: "\e636";
}
.icon-hb-more2:before {
  content: "\e637";
}
.icon-hb-more3:before {
  content: "\e638";
}
.icon-hb-more4:before {
  content: "\e639";
}
.icon-hb-rearrange:before {
  content: "\e69e";
}
.icon-hb-notifications:before {
  content: "\e664";
}
.icon-hb-notes:before {
  content: "\e63b";
}
.icon-hb-other:before {
  content: "\e63c";
}
.icon-hb-checkmark4:before {
  content: "\e63e";
}
.icon-hb-photo:before {
  content: "\e63f";
}
.icon-hb-photographer:before {
  content: "\e640";
}
.icon-hb-planner:before {
  content: "\e641";
}
.icon-hb-print:before {
  content: "\e642";
}
.icon-hb-profile:before {
  content: "\e643";
}
.icon-hb-receipt:before {
  content: "\e644";
}
.icon-hb-sadface:before {
  content: "\e645";
}
.icon-hb-send:before {
  content: "\e646";
}
.icon-hb-send2:before {
  content: "\e663";
}
.icon-hb-settings:before {
  content: "\e647";
}
.icon-hb-stationery:before {
  content: "\e648";
}
.icon-hb-stationery2:before {
  content: "\e649";
}
.icon-hb-textcolor:before {
  content: "\e64a";
}
.icon-hb-underline:before {
  content: "\e64d";
}
.icon-hb-venue:before {
  content: "\e64e";
}
.icon-hb-video:before {
  content: "\e64f";
}
.icon-hb-add1-b:before {
  content: "\e650";
}
.icon-hb-add3-b:before {
  content: "\e667";
}
.icon-hb-add2-b:before {
  content: "\e651";
}
.icon-hb-addfile-b:before {
  content: "\e652";
}
.icon-hb-adduser-b:before {
  content: "\e653";
}
.icon-hb-arrowdown-b:before {
  content: "\e654";
}
.icon-hb-arrowdown2-b:before {
  content: "\e655";
}
.icon-hb-arrowleft-b:before {
  content: "\e656";
}
.icon-hb-arrowleft2-b:before {
  content: "\e657";
}
.icon-hb-arrowright-b:before {
  content: "\e658";
}
.icon-hb-arrowright2-b:before {
  content: "\e659";
}
.icon-hb-arrowup-b:before {
  content: "\e65a";
}
.icon-hb-arrowup2-b:before {
  content: "\e65b";
}
.icon-hb-back1-b:before {
  content: "\e6bb";
}
.icon-hb-back2-b:before {
  content: "\e65d";
}
.icon-hb-back3-b:before {
  content: "\e65e";
}
.icon-hb-archive-b:before {
  content: "\e603";
}
.icon-hb-booking-b:before {
  content: "\e66d";
}
.icon-hb-budget-b:before {
  content: "\e660";
}
.icon-hb-call-b:before {
  content: "\e662";
}
.icon-hb-checkmark1-b:before {
  content: "\e63a";
}
.icon-hb-checkmark2-b:before {
  content: "\e69f";
}
.icon-hb-comment-b:before {
  content: "\e665";
}
.icon-hb-company-b:before {
  content: "\e666";
}
.icon-hb-compass-b:before {
  content: "\e670";
}
.icon-hb-creditcard-b:before {
  content: "\e63d";
}
.icon-hb-idcard-b:before {
  content: "\e668";
}
.icon-hb-dashboard-b:before {
  content: "\e669";
}
.icon-hb-deleteuser-b:before {
  content: "\e66a";
}
.icon-hb-download-b:before {
  content: "\e66b";
}
.icon-hb-dropdown-b:before {
  content: "\e66c";
}
.icon-hb-dropdown2-b:before {
  content: "\e672";
}
.icon-hb-duplicate22:before {
  content: "\e695";
}
.icon-hb-edit-b:before {
  content: "\e696";
}
.icon-hb-email-b:before {
  content: "\e697";
}
.icon-hb-exit1-b:before {
  content: "\e671";
}
.icon-hb-exit2-b:before {
  content: "\e698";
}
.icon-hb-file-b:before {
  content: "\e673";
}
.icon-hb-gridview_active-b:before {
  content: "\e6bc";
}
.icon-hb-gridview_inactive-b:before {
  content: "\e675";
}
.icon-hb-guest-b:before {
  content: "\e676";
}
.icon-hb-honeynews-b:before {
  content: "\e678";
}
.icon-hb-key-b:before {
  content: "\e679";
}
.icon-hb-list-booked:before {
  content: "\e67a";
}
.icon-hb-list-lead:before {
  content: "\e67b";
}
.icon-hb-list-lead2:before {
  content: "\e67c";
}
.icon-hb-listview_active-b:before {
  content: "\e67d";
}
.icon-hb-listview_inactive-b:before {
  content: "\e67e";
}
.icon-hb-location-b:before {
  content: "\e67f";
}
.icon-hb-more1-b:before {
  content: "\e680";
}
.icon-hb-more2-b:before {
  content: "\e681";
}
.icon-hb-more3-b:before {
  content: "\e682";
}
.icon-hb-more4-b:before {
  content: "\e683";
}
.icon-hb-move-b:before {
  content: "\e684";
}
.icon-hb-next1-b:before {
  content: "\e685";
}
.icon-hb-next2-b:before {
  content: "\e686";
}
.icon-hb-next3-b:before {
  content: "\e687";
}
.icon-hb-notes-b:before {
  content: "\e688";
}
.icon-hb-notifications-b:before {
  content: "\e921";
}
.icon-hb-save-b:before {
  content: "\e699";
}
.icon-hb-photo-b:before {
  content: "\e68c";
}
.icon-hb-print-b:before {
  content: "\e68d";
}
.icon-hb-profile-b:before {
  content: "\e68e";
}
.icon-hb-receipt-b:before {
  content: "\e68f";
}
.icon-hb-sadface-b:before {
  content: "\e690";
}
.icon-hb-settings-b:before {
  content: "\e692";
}
.icon-hb-trash-b:before {
  content: "\e693";
}
.icon-hb-video-b:before {
  content: "\e694";
}
.icon-hb-quote-b:before {
  content: "\e65f";
}
.icon-hb-nx-info-circle-42:before {
  content: "\e9f7";
}
.icon-hb-nx-triangle-exclamation-mark-42:before {
  content: "\e9f8";
}
.icon-hb-nx-calendar-star-42:before {
  content: "\e9ed";
}
.icon-hb-nx-cart-42:before {
  content: "\e9ee";
}
.icon-hb-nx-opposite-arrows-42:before {
  content: "\e9ef";
}
.icon-hb-nx-file-agreement-42:before {
  content: "\e9d5";
}
.icon-hb-nx-file-brochure-42:before {
  content: "\e9d6";
}
.icon-hb-nx-file-invoice-42:before {
  content: "\e9d7";
}
.icon-hb-nx-file-proposal-42:before {
  content: "\e9d8";
}
.icon-hb-nx-file-questionnaire-42:before {
  content: "\e9d9";
}
.icon-hb-nx-file-timeline-42:before {
  content: "\e9da";
}
.icon-hb-nx-megaphone-42:before {
  content: "\e984";
}
.icon-hb-nx-money-42:before {
  content: "\e981";
}
.icon-hb-nx-other-42:before {
  content: "\e982";
}
.icon-hb-nx-users-42:before {
  content: "\e983";
}
.icon-hb-nx-calendar-32:before {
  content: "\e9df";
}
.icon-hb-nx-chekbox-32:before {
  content: "\e9e0";
}
.icon-hb-nx-dolar-ciecle-32:before {
  content: "\e9e1";
}
.icon-hb-nx-drop-down-32:before {
  content: "\e9e2";
}
.icon-hb-nx-folder-ribbon-32:before {
  content: "\e9e3";
}
.icon-hb-nx-input-one-line-32:before {
  content: "\e9e4";
}
.icon-hb-nx-input-text-area-32:before {
  content: "\e9e5";
}
.icon-hb-nx-location-pin-32:before {
  content: "\e9e6";
}
.icon-hb-nx-message-32:before {
  content: "\e9e7";
}
.icon-hb-nx-phone-32:before {
  content: "\e9e8";
}
.icon-hb-nx-radio-button-32:before {
  content: "\e9e9";
}
.icon-hb-nx-users-plus-32:before {
  content: "\e9ea";
}
.icon-hb-nx-line-arrow-down-thick-32:before {
  content: "\e9dc";
}
.icon-hb-nx-file-agreement-32:before {
  content: "\e9cf";
}
.icon-hb-nx-file-brochure-32:before {
  content: "\e9d0";
}
.icon-hb-nx-file-invoice-32:before {
  content: "\e9d1";
}
.icon-hb-nx-file-proposal-32:before {
  content: "\e9d2";
}
.icon-hb-nx-file-questionnaire-32:before {
  content: "\e9d3";
}
.icon-hb-nx-file-timeline-32:before {
  content: "\e9d4";
}
.icon-hb-opportunities:before {
  content: "\e957";
}
.icon-hb-open-email:before {
  content: "\e94d";
}
.icon-hb-compass-c:before {
  content: "\e941";
}
.icon-hb-grid:before {
  content: "\e942";
}
.icon-hb-people:before {
  content: "\e943";
}
.icon-hb-suitcase:before {
  content: "\e944";
}
.icon-hb-profile3:before {
  content: "\e939";
}
.icon-hb-trophy:before {
  content: "\e91d";
}
.icon-hb-chair:before {
  content: "\e90f";
}
.icon-hb-cancel-b:before {
  content: "\e6b0";
}
.icon-hb-trash:before {
  content: "\e64b";
}
.icon-hb-nx-check-100:before {
  content: "\e9dd";
}
.icon-hb-tooltip-question:before {
  content: "\e956";
}
.icon-hb-automation-2:before {
  content: "\e949";
}
.icon-hb-tasks:before {
  content: "\e94b";
}
.icon-hb-workflow:before {
  content: "\e94c";
}
.icon-hb-plus:before {
  content: "\e948";
}
.icon-hb-search-mobile:before {
  content: "\e945";
}
.icon-hb-bell:before {
  content: "\e93d";
}
.icon-hb-activity:before {
  content: "\e93a";
}
.icon-hb-automation:before {
  content: "\e94a";
}
.icon-hb-calendar3:before {
  content: "\e92b";
}
.icon-hb-headset:before {
  content: "\e92c";
}
.icon-hb-e-sign:before {
  content: "\e92d";
}
.icon-hb-mobile:before {
  content: "\e92e";
}
.icon-hb-questionnaire2:before {
  content: "\e92f";
}
.icon-hb-quickbooks:before {
  content: "\e930";
}
.icon-hb-reports:before {
  content: "\e931";
}
.icon-hb-lock2:before {
  content: "\e932";
}
.icon-hb-team2:before {
  content: "\e933";
}
.icon-hb-templates:before {
  content: "\e934";
}
